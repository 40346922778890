
//************ For Production Use This***************************************************
export const API_BASE_URL = 'https://us-central1-jasem-2023.cloudfunctions.net'; // Production Base URL 
export const Web_Socket_URL = 'https://jasem-2023.uc.r.appspot.com' // Production Socket URL      
export const APP_URL = 'https://app.bezooz.com/' // Product APP URL
//************ For Production Use This***************************************************


// ************ For Testing-Development Use This*******************************************************
// export const API_BASE_URL = 'https://us-central1-jasem-2023.cloudfunctions.net'; // Testing-Development Base URL        
// export const Web_Socket_URL = 'https://development-dot-jasem-2023.uc.r.appspot.com' // Testing-Development Socket URL 
// export const APP_URL = 'https://mehul-bezoozdev.web.app/' // Testing-Development App URL

// export const API_BASE_URL = 'https://us-central1-jasem-2023.cloudfunctions.net'; // Testing-Development Base URL        
// export const Web_Socket_URL = 'https://stagging-dot-jasem-2023.uc.r.appspot.com' // Testing-Development Socket URL 
// export const APP_URL = 'https://app.bezooz.com/' // Testing-Development App URL
// ************ For Testing-Development Use This*******************************************************


// ************ For Local Use This*******************************************************
// export const API_BASE_URL = 'http://localhost:8080'; // Production Base URL       
// export const Web_Socket_URL = 'http://localhost:8080' // Local Socket URL 
// export const APP_URL = 'http://localhost:3200/' // Local APP URL
// ************ For Local Use This*******************************************************

export const WEB_SOCKET_URLS = {
    // If any API used add your URL here    
    FileUpload: `${Web_Socket_URL}/FileUpload`,
    GetPrivateClass: `${Web_Socket_URL}/GetPrivateClass`,
    GetTutorCourses: `${Web_Socket_URL}/GetTutorCourses`,
    SavePrivateClass: `${Web_Socket_URL}/SavePrivateClass`,
    SaveTutorCourse: `${Web_Socket_URL}/SaveTutorCourse`,
    SaveTutorSessions: `${Web_Socket_URL}/SaveTutorSessions`,
    ChangePrivateClassStatus: `${Web_Socket_URL}/ChangePrivateClassStatus`,
    EditTutorCourse: `${Web_Socket_URL}/EditTutorCourse`,
    EditTutorSession: `${Web_Socket_URL}/EditTutorSession`,
    GetStudentPrivateClass: `${Web_Socket_URL}/GetStudentPrivateClass`,
    GetStudentCourse: `${Web_Socket_URL}/GetStudentCourse`,
    GetStudentSessions: `${Web_Socket_URL}/GetStudentSessions`,
    GetPrivateClassPaymentLink: `${Web_Socket_URL}/GetPrivateClassPaymentLink`,
    GetTutorClassPaymentLink: `${Web_Socket_URL}/GetTutorClassPaymentLink`,
    GetPrivateClassStatus: `${Web_Socket_URL}/GetPrivateClassStatus`,
    GetCourseClassStatus: `${Web_Socket_URL}/GetCourseClassStatus`,
    GetStudentsList: `${Web_Socket_URL}/GetStudentsList`,
    GetAllTopics: `${Web_Socket_URL}/GetAllTopics`,
    getTutorSessions: `${Web_Socket_URL}/getTutorSessions`    
};     

export const API_URLS = {
    // If any API used add your URL here
    GenerateAgoraToken: `${API_BASE_URL}/GenerateAgoraToken`,
    // AgoraRecordingURL: `${API_BASE_URL}/AgoraRecordingURL`,
    // SaveScreenShot: `${API_BASE_URL}/SaveScreenShot`,
    // SetSessionSID: `${API_BASE_URL}/SetSessionSID`,
    // ChangeClassStatus: `${API_BASE_URL}/ChangeClassStatus`,
    AgoraClassEnd: `${API_BASE_URL}/AgoraClassEnd`,
    // GetLocalizationsString: `${API_BASE_URL}/GetLocalizationsString`
};